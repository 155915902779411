<script lang="ts">
	import ContactLink from '$lib/contactLink.svelte';
	import Briefcase from '$lib/icons/briefcase.svelte';
	import Office from '$lib/icons/office.svelte';
	import Pin from '$lib/icons/pin.svelte';
	import Image from '$lib/image/image.svelte';
</script>

<svelte:head>
	<title>Timo Prüße</title>
	<meta name="description" content="Timo Prüße - Full-Stack-Developer, based in Germany" />
</svelte:head>

<div
	class="max-w-screen-xl w-full flex lg:max-xl:flex-col items-center xl:h-full flex-wrap mx-auto mt-20 md:mt-32 lg:mt-28 xl:mt-0 relative flex-1"
>
	<div
		class="w-full lg:w-3/4 xl:w-[64%] 2xl:w-3/5 rounded-l-xl max-xl:rounded-r-xl shadow-2xl bg-black/10 backdrop-blur-lg mx-4 lg:mx-auto xl:mx-0 text-white"
	>
		<div class="px-4 md:px-12 pt-8 pb-6 md:pt-12 md:pb-8 text-center xl:text-left">
			<Image
				mobile
				baseType="jpg"
				src={{ jpg: '/images/timo_small.jpg', webp: '/images/timo_small.webp' }}
				class="xl:hidden rounded-full shadow-xl mx-auto -mt-24 md:-mt-32 lg:-mt-36 h-40 w-40 md:h-48 md:w-48 lg:h-52 lg:w-52 object-cover object-center aspect-square"
				alt="Portrait of Timo"
				height="300"
				width="300"
			/>

			<h1 class="text-4xl xl:text-5xl font-semibold mt-8 xl:mt-0">Timo Prüße</h1>
			<div class="mx-auto pt-6 md:pt-8 mb-6 xl:pt-10 xl:mb-8 border-b border-white/30" />
			<div class="w-full sm:w-3/4 md:w-2/3 mx-auto xl:w-full">
				<p
					class="mt-4 font-semibold text-lg md:text-xl lg:text-2xl flex max-xl:flex-row-reverse items-center justify-between xl:justify-start group"
				>
					<Office class="h-6 lg:h-8 text-white/75 mr-2 xl:mr-6 group-hover:text-white" />
					<a
						href="https://no.studio"
						target="_blank"
						rel="noopener noreferrer"
						class="transform-gpu hover:scale-110 transition-transform origin-left will-change-transform whitespace-nowrap"
					>
						@niceoutside
					</a>
				</p>
				<p
					class="mt-4 font-semibold text-lg md:text-xl lg:text-2xl flex max-xl:flex-row-reverse items-center justify-between xl:justify-start group"
				>
					<Briefcase class="h-6 lg:h-8 text-white/75 mr-2 xl:mr-6 group-hover:text-white" />
					Full-Stack-Developer
				</p>
				<p
					class="mt-4 font-semibold text-lg md:text-xl lg:text-2xl flex max-xl:flex-row-reverse items-center justify-between xl:justify-start group"
				>
					<Pin class="h-6 lg:h-8 text-white/75 mr-2 xl:mr-6 group-hover:text-white" />
					Braunschweig / Hamburg
				</p>
			</div>
			<div
				class="mt-8 md:mt-14 font-serif flex relative py-8 group w-full md:w-2/3 xl:w-3/4 max-xl:md:mx-auto"
			>
				<div
					class="pointer-events-none w-10 h-10 xl:w-16 xl:h-16 text-5xl md:text-6xl xl:text-7xl text-white/75 group-hover:text-white absolute top-0 left-0 pl-1 xl:pl-3 pt-2 rounded-tl-xl border-l border-t border-white/50 group-hover:border-white/75"
				>
					“
				</div>
				<p
					class="px-12 sm:px-14 md:px-16 flex-1 text-lg md:text-2xl xl:text-3xl italic text-white/75 group-hover:text-white text-center"
				>
					I love teaching machines<br />how to solve problems.
				</p>
				<div
					class="pointer-events-none w-12 h-12 xl:w-16 xl:h-16 text-5xl md:text-6xl xl:text-7xl text-white/75 group-hover:text-white absolute bottom-0 right-0 pb-2 pr-3 rounded-br-xl border-r border-b border-white/50 group-hover:border-white/75"
				>
					<div class="transform-gpu translate-y-1/4 translate-x-1/3">”</div>
				</div>
			</div>

			<div
				class="mt-8 md:mt-12 w-4/5 xl:w-full mx-auto flex flex-wrap gap-x-8 items-center max-xl:justify-center"
			>
				<ContactLink
					url="https://twitter.com/TimoPruesse/"
					path="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"
					hoverPath="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
					title="Twitter"
				/>
				<ContactLink
					url="https://github.com/timopruesse/"
					path="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
					title="GitHub"
				/>
				<ContactLink
					url="https://www.instagram.com/timopruesse/"
					path="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
					title="Instagram"
				/>
			</div>
		</div>
	</div>
	<div class="h-full w-full xl:w-[36%] 2xl:w-2/5 flex items-center relative max-xl:hidden">
		<Image
			src={{ jpg: '/images/timo_large.jpg', webp: '/images/timo_large.webp' }}
			baseType="jpg"
			alt="Portrait of Timo"
			class="rounded-xl shadow-2xl object-contain max-xl:hidden"
			height="1024"
			width="768"
		/>
	</div>
	<nav
		class="w-full flex justify-center xl:justify-end flex-1 max-xl:my-6 xl:absolute xl:bottom-6 2xl:bottom-8 xl:right-6 2xl:right-8 self-start"
	>
		<a href="/imprint" class="text-white hover:underline">Imprint</a>
	</nav>
</div>
